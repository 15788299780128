<template>
  <template v-if="canSeeNotes">
    <teleport
      v-if="dealNotesOptions.state === NoteState.Draggable"
      to="#modals"
    >
      <vue-resizable
        data-cy="deal-notes-draggable"
        class="z-[101]"
        drag-selector=".handle"
        :width="dealNotesOptions.width"
        :height="dealNotesOptions.height"
        :top="dealNotesOptions.y"
        :left="dealNotesOptions.x"
        :min-width="notesWidthAndHeight.minWidth"
        :min-height="notesWidthAndHeight.minHeight"
        @mount="updatePosition"
        @resize:end="updatePosition"
        @drag:end="updatePosition"
      >
        <div
          class="shadow-lg w-full bg-white rounded-md border border-gray-300"
          :class="
            dealNotesOptions.minimized ? 'h-[55px]' : 'h-full overflow-hidden'
          "
        >
          <div
            ref="handle"
            data-cy="handle"
            class="absolute top-[18px] left-4 handle"
          >
            <icon-base
              :icon="IconCardView"
              width="16"
              height="16"
              view-box="0 0 24 24"
              class="transform rotate-90 cursor-grab handle"
            />
          </div>
          <notes-content ref="draggableNotes" :notes-loading="loading" />
        </div>
      </vue-resizable>
    </teleport>
    <div
      v-else-if="dealNotesOptions.state === NoteState.Static"
      id="dealsNotesWrapper"
      data-cy="deal-notes-static"
      class="w-full md:w-1/3 lg:w-1/4 bg-white shadow-2xl z-100 pt-4 h-screen"
    >
      <notes-content :notes-loading="loading" @popover:init="initPosition" />
    </div>
  </template>
</template>
<script lang="ts">
import { useDeals } from "@/hooks/deals";
import { usePromiseWrapper } from "@/hooks/common";
import { computed, reactive, ref, watch } from "vue";
import queryString from "query-string";
import { useNotification } from "@/hooks/notifications";
import { useLocalStorageSetting } from "@/hooks/options";
import { useI18n } from "vue-i18n";
import useApplicationsStore from "@/stores/applications";
import { storeToRefs } from "pinia";
import { DEFAULT_DEAL_NOTES_OPTIONS } from "@/helpers/constants/deals";
import type { DealNotesOptions, ResizableEventValues } from "@/models/common";
import { NoteState } from "@/enums/notes";
</script>
<script setup lang="ts">
import NotesContent from "@/components/deals/NotesContent.vue";
import IconCardView from "@/components/icons/IconCardView.vue";
import VueResizable from "vue-resizable";

const initialParams = queryString.parse(location.search);
const { activeDeal, dealNotes } = useDeals();
const applicationsStore = useApplicationsStore();
const { showMessage } = useNotification();
const { t } = useI18n();
const dealNotesOptions = useLocalStorageSetting(
  "dealNotesOptions",
  DEFAULT_DEAL_NOTES_OPTIONS
);

const { shouldFetchNotes, canSeeNotes } = storeToRefs(applicationsStore);
const { loading, fetchWrapper: getNotes } = usePromiseWrapper(
  async () => {
    await applicationsStore.getApplicationNotes({
      applicationId: activeDeal.value.id,
      types: ["application"]
    });
  },
  { onError: () => showMessage(t("COMMON.ACCESS_RESTRICTED"), "error") }
);

const draggableNotes = ref<InstanceType<typeof NotesContent> | null>(null);
const handle = ref<HTMLElement | null>(null);
// min values are the smallest the component can go
// and still be practically usable
// width and height are initial values
const notesWidthAndHeight = ref({
  width: DEFAULT_DEAL_NOTES_OPTIONS.width,
  height: DEFAULT_DEAL_NOTES_OPTIONS.height,
  minWidth: 270,
  minHeight: 250
});

const params = reactive({
  note: (initialParams.note || null) as string | unknown
});

const numOfNotes = computed(() => dealNotes.value?.length);
const activeDealId = computed(() => activeDeal.value.id);

const initPosition = (position: Pick<DealNotesOptions, "x" | "y">) =>
  updatePosition(
    {
      left: position.x,
      top: position.y,
      width: notesWidthAndHeight.value.width,
      height: notesWidthAndHeight.value.height
    },
    true
  );

const updatePosition = (
  positionAndEvent: ResizableEventValues,
  init = false
) => {
  // if we are initializing the draggable from the static version
  // we need to offset x and y a bit for a clean position of the draggable
  dealNotesOptions.value = {
    ...dealNotesOptions.value,
    x: positionAndEvent.left - (init ? 150 : 0),
    y: positionAndEvent.top + (init ? 50 : 0),
    width: positionAndEvent.width,
    height: positionAndEvent.height
  };
};

const scrollNoteIntoView = () => {
  const note = document.getElementById(params.note as string) as HTMLDivElement;
  note?.scrollIntoView({
    block: "start",
    inline: "nearest",
    behavior: "smooth"
  });
  setTimeout(() => {
    note?.classList.add("bg-blue-50");
  }, 300);
  setTimeout(() => {
    note?.classList.remove("bg-blue-50");
  }, 3000);
};

watch(
  [activeDealId, canSeeNotes, dealNotesOptions],
  async () => {
    if (
      !activeDealId.value ||
      !canSeeNotes.value ||
      dealNotesOptions.value.state === NoteState.Closed ||
      !shouldFetchNotes.value
    ) {
      return;
    }
    applicationsStore.updateFetchServiceStatus(["notes"], false);
    getNotes();
  },
  { deep: true, immediate: true }
);

watch(
  [activeDeal, params, loading, numOfNotes],
  () => {
    if (activeDeal.value && params.note && numOfNotes.value) {
      const isNoteInDeal = dealNotes.value.some(
        (note) => note.id === Number(params.note)
      );
      if (!isNoteInDeal) {
        return;
      }
      if (dealNotesOptions.value.state === NoteState.Draggable) {
        return;
      }
      if (!loading.value && dealNotesOptions.value.state !== NoteState.Closed) {
        scrollNoteIntoView();
      }
    }
  },
  { immediate: true }
);
</script>
<style scoped>
.content-height {
  height: 95%;
}
</style>
